import { createRouter, createWebHistory } from 'vue-router'

import LandingPage from '../views/LandingPage.vue'
import SuccessPage from '../views/SuccessPage.vue'
import FailurePage from '../views/FailurePage.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: LandingPage,
        },
        {
            path: '/success',
            component: SuccessPage,
        },
        {
            path: '/failure/:orderCode',
            component: FailurePage,
        }
    ]
  })

export default router