<template>
  <div class="blue mattone">
    <div class="header">
      <div class="header-text">
        <span class="logo_text" @click="redirect()">CroCube</span>
        <div class="header-subtext"> {{ translations['header_subtext'][lang] }}</div>
      </div>
    </div>

    <div class="first-card">
      <div class="container">
        <div class="container-main-text">
          {{ translations['title1'][lang] }} <span class="container-special-text">
            {{ translations['title2'][lang] }}
          </span> <br>
          {{ translations['title3'][lang] }}
        </div>
        <div class="container-subtext">
          {{ translations['subtitle'][lang] }}
        </div>

          <button class="donation-btn" @click="scrollToStep('step1')">
            {{ translations['donate'][lang] }}
          </button>
       
      </div>
      <img src="../assets/images/planet.png" class="planet-img">
    </div>

    <div id="step1" class="step-anchor">
      <div class="step">
        <div>{{ translations['step'][lang] }}</div>
        <div class="step-title"> {{ translations['choose_package'][lang] }}</div>
      </div>

      <div class="models-collection">
        <div class="donation-column">
          <div class="donation-model"
          @click="handleCheckboxChange(donationPackages[0])"
            :class="{ active: donationPackages[0].checked == true, inactive: donationPackages[0].checked == false }">
            <div class="radio-checkbox">
              <input type="radio" class="donation-radio" :value="donationPackages[0].id"
                :checked="donationPackages[0].checked"
                :autocomplete="autocomplete">
            </div>
            <div class="model-title">{{ donationPackages[0].cost }} €</div>
            <div class="model-subtitle">{{ donationPackages[0].name[lang] }}</div>
            <div class="model-text" v-html="donationPackages[0].description[lang]"></div>

            <div class="input-text" v-if="donationPackages[0].checked">
              {{ translations['extra_donation'][lang] }}
            </div>
            <div class="pay-input" v-if="donationPackages[0].checked">
              <input class="value-input" type="text" v-model="donationPackages[0].addedValue" @input="handleInput(0)"
               :placeholder="translations['amount'][lang]" maxlength="7"
               :autocomplete="autocomplete"/>
              <div style="padding-left:0.3em; padding-right: 0.5em;">€</div>
            </div>
          </div>

          <div class="donation-model"
          @click="handleCheckboxChange(donationPackages[1])"
            :class="{ active: donationPackages[1].checked == true, inactive: donationPackages[1].checked == false }">
            <div class="radio-checkbox">
              <input type="radio" class="donation-radio" :value="donationPackages[1].id"
                :checked="donationPackages[1].checked"
                :autocomplete="autocomplete">
            </div>
            <div class="model-title">{{ donationPackages[1].cost }} €</div>
            <div class="model-subtitle">{{ donationPackages[1].name[lang] }}</div>
            <div class="model-text" v-html="donationPackages[1].description[lang]"></div>
            <div class="input-text" v-if="donationPackages[1].checked">
              {{ translations['extra_donation'][lang] }}
            </div>
            <div class="pay-input" v-if="donationPackages[1].checked">
              <input class="value-input" type="text" v-model="donationPackages[1].addedValue" 
              @input="handleInput(1)" :placeholder="translations['amount'][lang]" maxlength="7"
              :autocomplete="autocomplete" />
              <div style="padding-left:0.3em; padding-right: 0.5em;">€</div>
            </div>

          </div>
        </div>

        <div class="donation-column">
          <div class="donation-model"
          @click="handleCheckboxChange(donationPackages[2])"
            :class="{ active: donationPackages[2].checked == true, inactive: donationPackages[2].checked == false }">
            <div class="radio-checkbox">
              <input type="radio" class="donation-radio" :value="donationPackages[2].id"
                :checked="donationPackages[2].checked"
                :autocomplete="autocomplete">
            </div>
            <div class="model-title">{{ donationPackages[2].cost }} €</div>
            <div class="model-subtitle">{{ donationPackages[2].name[lang] }}</div>
            <div class="model-text" v-html="donationPackages[2].description[lang]"></div>
            <div class="input-text" v-if="donationPackages[2].checked">
              {{ translations['extra_donation'][lang] }}
            </div>
            <div class="pay-input" v-if="donationPackages[2].checked">
              <input class="value-input" type="text" v-model="donationPackages[2].addedValue" 
              @input="handleInput(2)" :placeholder="translations['amount'][lang]" maxlength="7"
              :autocomplete="autocomplete"/>
              <div style="padding-left:0.3em; padding-right: 0.5em;">€</div>
            </div>
          </div>

          <div class="donation-model"
          @click="handleCheckboxChange(donationPackages[3])"
            :class="{ active: donationPackages[3].checked == true, inactive: donationPackages[3].checked == false }">
            <div class="radio-checkbox">
              <input type="radio" class="donation-radio" :value="donationPackages[3].id"
                :checked="donationPackages[3].checked"
                :autocomplete="autocomplete">
            </div>
            <div class="model-title">{{ donationPackages[3].cost }} €</div>
            <div class="model-subtitle">{{ donationPackages[3].name[lang] }}</div>
            <div class="model-text" v-html="donationPackages[3].description[lang]"></div>
            <div class="input-text" v-if="donationPackages[3].checked">
              {{ translations['extra_donation'][lang] }}
            </div>
            <div class="pay-input" v-if="donationPackages[3].checked">
              <input class="value-input" type="text" v-model="donationPackages[3].addedValue"
               @input="handleInput(3)" :placeholder="translations['amount'][lang]" maxlength="7"
               :autocomplete="autocomplete"/>
              <div style="padding-left:0.3em; padding-right: 0.5em;">€</div>
            </div>

          </div>
        </div>

        <div class="donation-column">
          <div class="donation-model"
          @click="handleCheckboxChange(donationPackages[4])"
            :class="{ active: donationPackages[4].checked == true, inactive: donationPackages[4].checked == false }">
            <div class="radio-checkbox">
              <input type="radio" class="donation-radio" :value="donationPackages[4].id"
                :checked="donationPackages[4].checked"
                :autocomplete="autocomplete">
            </div>
            <div class="model-title">{{ donationPackages[4].cost }} €</div>
            <div class="model-subtitle">{{ donationPackages[4].name[lang] }}</div>
            <div class="model-text" v-html="donationPackages[4].description[lang]"></div>
            <div class="input-text" v-if="donationPackages[4].checked">
              {{ translations['extra_donation'][lang] }}
            </div>
            <div class="pay-input" v-if="donationPackages[4].checked">
              <input class="value-input" type="text" v-model="donationPackages[4].addedValue" 
              @input="handleInput(4)" :placeholder="translations['amount'][lang]" maxlength="7"
              :autocomplete="autocomplete"/>
              <div style="padding-left:0.3em; padding-right: 0.5em;">€</div>
            </div>
          </div>

          <div class="donation-model"
          @click="handleCheckboxChange(donationPackages[5])"
            :class="{ active: donationPackages[5].checked == true, inactive: donationPackages[5].checked == false }">
            <div class="radio-checkbox">
              <input type="radio" class="donation-radio" :value="donationPackages[5].id"
                :checked="donationPackages[5].checked"
                :autocomplete="autocomplete">
            </div>
            <div class="model-title">{{ donationPackages[5].cost }} €</div>
            <div class="model-subtitle">{{ donationPackages[5].name[lang] }}</div>
            <div class="model-text" v-html="donationPackages[5].description[lang]"></div>
            <div class="input-text" v-if="donationPackages[5].checked">
              {{ translations['extra_donation'][lang] }}
            </div>
            <div class="pay-input" v-if="donationPackages[5].checked">
              <input class="value-input" type="text" v-model="donationPackages[5].addedValue" 
              @input="handleInput(5)" :placeholder="translations['amount'][lang]" maxlength="7"
              :autocomplete="autocomplete"/>
              <div style="padding-left:0.3em; padding-right: 0.5em;">€</div>
            </div>

          </div>
        </div>
      </div>


      <div id="step2" class="step-title">
        {{ translations['email_prompt'][lang] }}
      </div>

      <div class="input-container">
        <input 
          type="text" 
          class="email-input" 
          :placeholder="translations['name'][lang]"
           maxlength="100" 
           v-model="fullName"
          :autocomplete="autocomplete" 
          required
          :class="{ 'error-border': submit && !fullName }"
        >
        <div
          v-if="submit && !fullName"
          class="error-message"
        >
            {{ translations['required'][lang] }}
        </div>
      </div>
      <br>
      <div class="input-container">
        <input 
          type="email" 
          class="email-input" 
          placeholder="example@mail.com" 
          maxlength="100" 
          v-model="email"
          :autocomplete="autocomplete" 
          required
          :class="{ 'error-border': submit && (!email || !isValidEmail(this.email)) }"
          >
        <div
          v-if="submit && (!email || !isValidEmail(this.email))"
          class="error-message"
        >
            {{ !email ? translations['required'][lang] : translations['invalid_email'][lang]}}
        </div>
      </div>
      <br>
      <div class="input-container" v-if="selectedDonationId != 6 && selectedDonationId != null">
        <input 
          type="email" 
          class="email-input" 
          :placeholder="translations['address'][lang]" 
          maxlength="200" 
          v-model="address"
          :autocomplete="autocomplete"
          :class="{ 'error-border': submit && !address }"
        >
        <div
          v-if="submit && !address"
          class="error-message"
        >
            {{ translations['required'][lang] }}
        </div>
      </div>
      <br>
      <button class="donation-btn" @click="scrollToStep('step2');nextStep()" type="submit">
        {{ translations['next_step'][lang] }}
      </button>
      <div class="poweredby">
        <div style="padding-right: 1em; padding-top: 0.2em;">{{ translations['powered_by'][lang] }}</div> 
        <img src="@/assets/images/vivaWalletLogo.png" class="logo">
      </div>
    </div>

    <div v-if="error.length > 0">
      <div v-for="(e, index) in error" :key="index" class="error-msg">
        {{ e }}
      </div>
    </div>

    <div style="padding-top: 12em;"></div>
  </div>
  <FooterComponent :lang="lang"></FooterComponent>
</template>

<script>
import FooterComponent from '@/components/Footer.vue';
import Translations from '@/assets/Translations.json';
import axios from 'axios';

export default {
  name: 'LandingPage',
  components: {
    FooterComponent
  },
  props: {
    lang: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      translations: Translations,
      selectedDonationId: null,
      autocomplete: 'off',
      email: null,
      fullName: null,
      address: null,
      payment: 0,
      error: [],
      submit: false,
      donationPackages: [
        {
          id: 1,
          name: {
            hr: "DONACIJA BIJELOG PATULJKA",
            en: "WHITE DWARF DONATION"
          },
          description:
          {
            hr: "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Naljepnica CroCube misije</div>" +
              "<div class='inline'>Rok dostave: nakon završetka kampanje. </div> Cijena u kunama iznosi 105,48 HRK.",
            en: "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>CroCube mission sticker</div>" +
              "<div class='inline'>Delivery deadline: after the end of the campaign. </div> The price in kuna is 105.48 HRK."
          },
          cost: 15,
          checked: false,
          addedValue: ''
        },
        {
          id: 2,
          name: {
            hr: "DONACIJA CRVENOG DIVA",
            en: "RED GIANT DONATION"
          },
          description: {
            hr: "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Naljepnica CroCube misije</div> " +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Brendirani privjesak za ključeve </div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Majica kratkih rukava. </div>" +
              "<div class='inline'> Rok dostave: nakon završetka kampanje.</div> Cijena u kunama iznosi 753,45 HRK.",
            en: "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>CroCube mission sticker</div> " +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Branded keychain </div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Short sleeve T-shirt. </div> " +
              "<div class='inline'> Delivery deadline: after the end of the campaign.</div> The price in kuna is HRK 753.45."
          },
          cost: 100,
          checked: false,
          addedValue: ''
        },
        {
          id: 3,
          name: {
            hr: "HOĆU POKLON!",
            en: "I WANT A GIFT!"
          },
          description: {
            hr: "<div class='inline'>Biraj jedan od ova tri poklona: </div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Privjesak i naljepnica</div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Brendirana majica kratkih rukava</div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Platnena torba i olovka</div>" +
              "<div class='inline'>Rok dostave: nakon završetka kampanje.</div> Cijena u kunama iznosi 203,43 HRK.",
            en: "<div class='inline'>Choose one of these three gifts: </div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Pendant and sticker</div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Branded T-shirt</div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Canvas bag and pen</div>" +
              "<div class='inline'>Delivery deadline: after the end of the campaign.</div> The price in kuna is HRK 203.43."
          },
          cost: 30,
          checked: false,
          addedValue: ''
        },
        {
          id: 4,
          name: {
            hr: "GALAKSIJA DONACIJA",
            en: "GALAXY DONATION"
          },
          description: {
            hr: "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Brendirani privjesak za ključeve</div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Naljepnica CroCube misije </div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Platnena torba (brending misije + tekst)</div> " +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Majica kratkih rukava </div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Olovka</div>" +
              "<div class='inline'>Rok dostave: nakon završetka kampanje.</div> Cijena u kunama iznosi 1205,52 HRK.",
            en: "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Branded keychain</div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>CroCube mission sticker </div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Canvas bag (mission branding + text)</div> " +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>T-shirt </div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Pen</div>" +
              "<div class='inline'>Delivery deadline: after the end of the campaign.</div> The price in kuna is 1205.52 HRK."
          },
          cost: 160,
          checked: false,
          addedValue: ''
        },
        {
          id: 5,
          name: {
            hr: "PLANETARNA DONACIJA",
            en: "PLANETARY DONATION"
          },
          description: {
            hr: "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Naljepnica CroCube misije </div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Brendirani privjesak za ključeve </div>" +
              "<div class='inline'>Rok dostave: nakon završetka kampanje.</div> Cijena u kunama iznosi 354,12 HRK.",
            en: "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>CroCube mission sticker </div>" +
              "<div class='inline'><div class='gift-icon' style='display: inline-block;'></div>Branded keychain </div>" +
              "<div class='inline'>Delivery deadline: after the end of the campaign.</div> The price in kuna is HRK 354.12."
          },
          cost: 50,
          checked: false,
          addedValue: ''
        },
        {
          id: 6,
          name: {
            hr: "PROIZVOLJNA DONACIJA",
            en: "ARBITRARY DONATION"
          },
          description: {
            hr: "Unesi željeni iznos za donaciju.<br> Ovaj model ne sadržava poklone.",
            en: "Enter the desired amount for the donation.<br> This model does not contain gifts."
          },
          cost: 1,
          checked: false,
          addedValue: ''
        }
      ]
    };
  },
  created(){
    document.title = "CroCube " + this.translations['donations'][this.lang];
  },
  mounted() {
    setTimeout(() => {
      this.autocomplete = 'on';
    }, 200);
    window.addEventListener('resize', this.handleResize);

    this.handleResize();
  },
  beforeUnmount() {
    this.selectedDonationId = null;
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    redirect() {
      window.location.href = 'https://crocube.hr/'
    },
    scrollToStep(elementId) {
      const targetElement = document.getElementById(elementId);
      if (targetElement) {
        const targetPosition = targetElement.offsetTop - 90;

        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth'
        });
      }
    },
    handleResize() {
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

      if (viewportWidth <= 1273) {
        this.donationPackages.sort((a, b) => {
          if (a.cost === 1) return 1;
          if (b.cost === 1) return -1;
          return a.cost - b.cost;
        });
      } else {
        this.donationPackages.sort((a, b) => a.id - b.id);
      }
    },
    handleCheckboxChange(donation) {
      let id = donation.id;
      if (this.selectedDonationId !== null && this.selectedDonationId !== id) {
        let oldPackage = this.donationPackages.find(item => item.id === this.selectedDonationId);
        oldPackage.addedValue = '';
        oldPackage.checked = false;
        this.selectedDonationId = null;
      }

      this.selectedDonationId = id;
      let newPackage = this.donationPackages.find(item => item.id === id);
      newPackage.checked = true;
    },
    isChecked(id) {
      return this.selectedDonationId === id;
    },
    handleInput(index) {
      let value = parseInt(this.donationPackages[index].addedValue)
      if (!isNaN(value) && value > 0) {
        this.donationPackages[index].addedValue = value;
      } else {
        this.donationPackages[index].addedValue = '';
      }
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email)
    },
    async nextStep() {
      this.submit = true;
      this.error = [];
      //Error handling
      if (!this.selectedDonationId) {
        this.error.push(this.translations['no_selected_package'][this.lang]);
      }

      if (this.email && this.fullName && this.isValidEmail(this.email) && this.selectedDonationId && (this.selectedDonationId === 6 || this.address))  {
        await this.storeOrder();
      }
    },
    async storeOrder() {
      let selectedPackage = this.donationPackages.find(item => item.id === this.selectedDonationId);
      if (selectedPackage.addedValue === '') {
        selectedPackage.addedValue = 0;
      }
      let amountCent = selectedPackage?.cost * 100 + parseInt(selectedPackage.addedValue, 10) * 100;

      
      await axios.post(process.env.VUE_APP_API_URL + '/orders/store',
        {
          email: this.email,
          address: this.address,
          fullName: this.fullName,
          totalAmount: amountCent,
          userAddedAmount: parseInt(selectedPackage.addedValue, 10) * 100,
          packageAmount: selectedPackage?.cost * 100,
          packageId: this.selectedDonationId
        }).then(
          res => {
            if (res?.status === 200 && res?.data?.data?.order?.id) {
              this.orderRef = res.data.data.order.viva_wallet_order_code;
              window.location.href = process.env.VUE_APP_VIVA_WALLET_CHECKOUT_URL + '?ref=' + this.orderRef + '&color=001489';
            } 
          }
        ).catch(e => {
          console.log(e)
        })
    }
  }
}
</script>

<style scoped>
.mattone {
  font-family: Mattone;
}

.header {
  position: -webkit-sticky;
  position: sticky;
  opacity: 1;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  background-color: #FFFFFF1A;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.1em;
  display: flex;
  z-index: 1;
  padding-top: 12px;
}

.header-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding-top: 0.5em;
  color: #FCFCFC;
  opacity: 1;
  letter-spacing: 0px;
  font-weight: 500;
  padding-left: 5em;
  font-size: 24px;
  z-index: 99;
}

.logo_text:hover {
  color: #ff0000 !important;
  cursor: pointer;
}

.logo {
  filter: brightness(0) invert(1);
}

.poweredby {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #FCFCFC;
  padding-top: 1.4em;
  font: 1.2em Mattone;
}

.header-subtext {
  font: 16px Roboto;
  color: #DBDBDB;
  opacity: 0.8;
  padding-top: 0.2em;
  padding-bottom: 1.3em;
}

.first-card {
  display: flex;
  padding-bottom: 3em;
  /* height: 60vw; */
}

.container {
  padding-top: 9em;
  padding-left: 9em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  height: 40vw;
}

.container-main-text {
  color: #FCFCFC;
  font-size: 4em;
  display: block;
  max-width: 100%;
}

.container-special-text {
  font-family: MaziusItalic;
  color: #B7D8E4;
}

.container-subtext {
  padding-top: 1em;
  font: 1.6em Mattone;
  color: #B7D8E4;
  display: inline-block;
  padding-bottom: 2em;
  margin-right: 2em;
}

.donation-btn {
  cursor: pointer;
  background: #B7D8E4;
  box-shadow: 0px 3px 10px #00000080;
  border: none;
  border-radius: 10px;
  opacity: 1;
  color: #001489;
  padding-left: 3.5em;
  padding-right: 3.5em;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  font: 1.2em Roboto;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.planet-img {
  margin-top: 6em;
  padding-left: 1em;
  max-width: none;
  max-height: 30vw;
  flex-shrink: 0;
  align-self: flex-end;
}

.step {
  color: #B7D8E4;
  font: 1em Mattone;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
}

.step-title {
  color: #FCFCFC;
  padding-top: 0.4em;
  font: 1.5em Mattone;
  padding-bottom: 0.4em;
}

.step-anchor {
  margin-top: 4em;
}

.models-collection {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding-top: 3em;
  padding-bottom: 3em;
  padding-left: 5em;
  padding-right: 5em;
}


.donation-column {
  display: flex;
  flex-direction: column;
  width: 33%;
  margin-left: 1em;
  margin-right: 1em;
}

.donation-model {
  border-radius: 10px;
  color: #DBDBDB;
  text-align: left;
  padding: 1.2em;
  font-family: Roboto;
  margin-bottom: 2em;
  height: fit-content;
}

.inactive {
  background: #FFFFFF1A 0% 0% no-repeat padding-box;
  border: 2px solid #FFFFFF1A;
}

.active {
  background: #FFFFFF33 0% 0% no-repeat padding-box;
  border: 2px solid #B7D8E4;
  opacity: 1;
  /* backdrop-filter: blur(40px); */
  /* -webkit-backdrop-filter: blur(40px); */
}

.model-title {
  font: 2em Mattone;
  color: #B7D8E4;
  opacity: 1;
}

.model-subtitle {
  font: 1.2em Mattone;
  color: #B7D8E4;
  padding-top: 1em;
  padding-bottom: 1em;
}

.model-text {
  font: 1em Roboto;
  color: #DBDBDB;
  display: inline;
}

.model-text :nth-last-child(1) {
  display: inline;
}

.model-text>>>.gift-icon {
  background-image: url('../assets/images/icons8-gift.svg');
  width: 20px;
  height: 20px;
  min-width: 20px;
  background-size: contain;
  background-repeat: no-repeat;
}

.model-text>>>.inline {
  display: flex;
  flex: 0;
  align-items: center;
  gap: 0.4em;
  padding-bottom: 0.4em;
  padding-top: 0.3em;
}

.radio-checkbox {
  display: flex;
  flex-direction: row-reverse;
}

.donation-radio,
input[type="radio"] {
  all: unset;
  background-color: #FFFFFF33;
  background: #FFFFFF33 0% 0% no-repeat padding-box;
  border: 2.5px solid #FFFFFF;
  border-radius: 35px;
  width: 30px;
  height: 30px;
  opacity: 1;
  color: #FFFFFF33;
  cursor: pointer;
}

.donation-radio:checked {
  cursor: pointer;
  background-color: #B7D8E4;
  box-shadow: inset 0 0 0 5px #00148990;
  border: 2px solid #B7D8E4;
}

.email-input {
  width: 45%;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  height: 3em;
  background-color: #001489;
  margin: 10px auto;
  text-align: center;
  font: 1em Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.6;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}


.pay-input {
  background: #FFFFFF33 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  opacity: 1;
  height: 3em;
  width: 30%;
  font: 1em Roboto;
  color: #FFFFFF;
  padding-left: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-right: 0.7em;
}

.value-input {
  all: unset;
  width: inherit;
  flex: 1;
}

.input-text {
  padding-top: 2em;
  margin-bottom: 1.4em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.email-input:focus,
.email-input:visited,
.pay-input:active {
  opacity: 1;
  background-color: #FFFFFF33;
  color: #FFFFFF;
  outline: none !important;
  transition: background-color 30ms ease-in;
}

input::placeholder {
  color: white;
  opacity: 0.6;
}

input:focus::placeholder {
  color: transparent;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */

/* Transition fade */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.blue {
  background-color: #001489;
}

.error-border {
  border: 1px solid red;
}


.error-msg {
  color: red;
  padding-top: 1.5em;
  display: flex;
  justify-content: center;
}

/* Responsive */

@media(max-width: 1534px) {
  .first-step {
    height: 60vw;
  }
}

@media (max-width: 1273px) {
  .models-collection {
    flex-direction: column;
  }

  .donation-column {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .donation-model {
    padding-left: 3em;
    padding-bottom: 2em;
  }
}

@media (max-width: 1000px) {
  .email-input {
    width: 70%;
  }

  .first-card {
    flex-direction: column-reverse;
    align-items: center;
  }

  .container {
    padding-top: 4em;
    text-align: center;
    align-items: center;
    padding-left: 0;
  }

  .container-subtext {
    padding-top: 5vw;
    padding-bottom: 10vw;
    max-width: 80%;
    margin-right: 0;
  }

  .planet-img {
    min-height: 45vw;
    align-self: auto;
  }

  .donation-btn {
    width: 70%;
  }

  .step {
    padding-top: 6em;
    margin-top: 20vw;
  }

  .models-collection {
    grid-template-columns: 1fr;
    padding-left: 2em;
    padding-right: 2em;
  }
}


@media(max-width: 700px) {
  .header-text {
    padding-left: 2em;
  }
}

@media(max-width: 610px) {
  .email-input {
    width: 85%;
  }

  .donation-btn {
    width: 85%;
  }

  .step {
    padding-top: 8em;
  }

}
</style>
