<template>
  <router-view :lang="lang"/>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      lang: ''
    };
  },
  beforeMount(){
    let fullUrl = window.location.href.split('/');
    if (fullUrl[3] == 'en' || fullUrl[3] == 'hr') {
      this.lang = fullUrl[3];
    } else {
      //if nothing in url, get browser language
      let browserLang = navigator.language.substring(0, 2).toLowerCase;
      if (browserLang == 'en' || browserLang == 'hr') {
        this.lang = browserLang;
      } else {
        //or set hr as default
        this.lang = 'hr';
      }
    }

    // this.currentPage = fullUrl[fullUrl.length - 1];
    // if (this.currentPage == 'korak-2') {
    //   this.stepNo = 2;
    // } else if (this.currentPage == 'korak-1' || this.currentPage == '') {
    //   this.stepNo = 1;
    // }
  }
}
</script>

<style>
@font-face {
  font-family: Mattone;
  src: url('~@/assets/fonts/Mattone-150.otf');
}

@font-face {
  font-family: Roboto;
  src: url('~@/assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: MaziusItalic;
  src: url('~@/assets/fonts/MAZIUSREVIEW20.09-Italic.otf');
}

html{
	scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
#app {
  font-family: Mattone, Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


</style>
