<template>
    <div class="blue mattone">
        <div class="header">
            <div class="header-text">
                <span @click="handleRedirect()">CroCube</span>
                <div class="header-subtext">Prva hrvatska kockica u svemiru</div>
            </div>
        </div>

        <div class="container">
            <div class="first-card">
                <img src="../assets/images/CroCube_Zahvalnica.png" class="thankyou-img">
            </div>
            <div class="thankyou-title">
                {{ translations['thankyou_title'][lang] }}
            </div>
            <div class="thankyou-text">
                {{ translations['thankyou_desc1'][lang] }}
                <p></p>
                {{ translations['thankyou_desc2'][lang] }}
            </div>

            <button class="back-btn" @click="handleRedirect()">
                {{ translations['about'][lang] }}
            </button>
            <div style="padding-bottom:8em;"></div>
        </div>

    </div>
    <FooterComponent :lang="lang"></FooterComponent>
</template>
  
<script>
import FooterComponent from '@/components/Footer.vue';
import Translations from '@/assets/Translations.json';

export default {
    name: 'SuccessPage',
    components: {
        FooterComponent
    },
    props: {
        lang: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            translations: Translations
        }
    },
    mounted() {
    },
    methods: {
        handleRedirect() {
            window.location.href = 'https://crocube.hr/'
        },
    }
}
</script>
  
<style scoped>
.mattone {
    font-family: Mattone;
}

.blue {
    background-color: #001489;
}

.header {
    position: -webkit-sticky;
    position: sticky;
    opacity: 1;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    background-color: #FFFFFF1A;
    top: 0;
    left: 0;
    width: 100%;
    height: 4.1em;
    display: flex;
    z-index: 1;
    padding-top: 12px;
}

.header-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    padding-top: 0.5em;
    color: #FCFCFC;
    opacity: 1;
    letter-spacing: 0px;
    font-weight: 500;
    text-shadow: 1px 1px 1px black;
    padding-left: 5em;
    font-size: 24px;
    z-index: 99;
}

.header-subtext {
    font: 16px Roboto;
    color: #DBDBDB;
    opacity: 0.8;
    padding-top: 0.2em;
    padding-bottom: 1.3em;
}

span:hover {
  color: #ff0000 !important;
  cursor: pointer;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.first-card {
    display: flex;
    padding-bottom: 4em;
    padding-top: 7em;
    justify-content: center;
}

.thankyou-img {
    min-width: 40vw;
    max-width: 70vw;
}

.thankyou-title {
    text-align: left;
    font: 2em Mattone;
    color: #FCFCFC;
    opacity: 1;
    padding-bottom: 2em;
}

.thankyou-text {
    text-align: center;
    font: 1em Roboto;
    color: #DBDBDB;
    opacity: 1;
    max-width: 60vw;
    padding-bottom: 5em;
}

.back-btn {
    background: #B7D8E4 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000080;
    border-radius: 10px;
    opacity: 1;
    color: #001489;
    padding-left: 4em;
    padding-right: 4em;
    padding-top: 1em;
    padding-bottom: 1em;
    font: 1.2em Roboto;
    font-weight: 600;
    letter-spacing: 0.05em;
    border: none;
    cursor: pointer;
}

@media(max-width: 1024px) {
    .thankyou-text {
        max-width: 84vw;
    }
}

@media(max-width: 800px) {
    .back-btn {
        width: 80vw;
    }
}
</style>
  