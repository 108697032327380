<template>
    <div class="footer background">
        <img class="logo" src="../assets/images/crocube-logo-red.svg" alt="CroCube Logo">

        <div class="socials">
            <div class="socials-title">{{ translations['social_media'][lang] }}</div>
            <div class="socials-text">
                {{ translations['social_media_desc'][lang] }}
            </div>
            <div class="icons">
                <a href="https://web.facebook.com/crocube">
                    <img src="../assets/images/facebook.svg" alt="CroCube Facebook" href="https://web.facebook.com/crocube">
                </a>
                <a href="https://www.instagram.com/crocube_satelit/">
                    <img src="../assets/images/instagram.svg" alt="CroCube Instagram">
                </a>
                <a href="https://www.linkedin.com/company/crocube/">
                    <img src="../assets/images/linkedin.svg" alt="CroCube LinkedIn">
                </a>

                <a href="https://twitter.com/CroCubeHr">
                    <img src="../assets/images/twitter.svg" alt="CroCube Twitter">
                </a>

                <a href="https://crocube.hr/">
                    <img src="../assets/images/website.svg" alt="CroCube">
                </a>

            </div>
            <div class="contact">
                <p><a href="mailto:contact@crocube.hr">contact@crocube.hr</a></p>
                <p><a href="tel:+385 99 19 09 472">+385 99 19 09 472</a></p>
            </div>
        </div>
    </div>
</template>
  
<script>
import Translations from '@/assets/Translations.json';

export default {
    name: 'FooterComponent',
    props: {
        lang: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            translations: Translations
        }
    }
}
</script>
  
<style scoped>
/* General */

a {
    text-decoration: none;
    color: inherit;
}

.background {
    /* width: 100vw; */
    background-color: #001489;
    opacity: 0.9;
}

.footer {
    margin-top: auto;
    padding-top: 5em;
    padding-bottom: 5em;
    padding-left: 3em;
    padding-right: 3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.socials {
    display: flex;
    flex-direction: column;
}

.socials-title {
    text-align: left;
    padding-bottom: 1.1em;
    font: 2em Mattone;
    letter-spacing: 0px;
    color: #FCFCFC;
    opacity: 1;
}

.socials-text {
    color: #DBDBDB;
    font: 1em Roboto;
    text-align: left;
}

.icons {
    padding-top: 1.5em;
    padding-bottom: 0.7em;
    display: flex;
    gap: 0.5em;
}

.icon-circle {
    cursor: pointer;
}

.contact {
    text-align: left;
    font: 1em Roboto;
    letter-spacing: 0px;
    color: #DBDBDB;
    opacity: 1;
}

.logo {
    max-width: 30vw;
}


/* Mobile */
@media (max-width: 850px) {
    .footer {
        flex-direction: column;
        padding-left: 2em;
        padding-top: 3em;
        align-items: flex-start;
    }

    .socials {
        padding-top: 3em;
    }

    .logo {
        max-width: 50vw;
    }
}
</style>
  